import introLecture from './lecture_notes/stat547u_lecture01.pdf';
import rkhsLecture from './lecture_notes/stat547u_lecture02.pdf';
import doubleDescentLecture from './lecture_notes/stat547u_lecture03.pdf';
import rmtLecture from './lecture_notes/stat547u_lecture04.pdf';
import implicitRegLecture from './lecture_notes/stat547u_lecture05.pdf';
import benignOverfitLecture from './lecture_notes/stat547u_lecture06.pdf';

export default {
  "introLecture": introLecture,
  "rkhsLecture": rkhsLecture,
  "doubleDescentLecture": doubleDescentLecture,
  "rmtLecture": rmtLecture,
  "implicitRegLecture": implicitRegLecture,
  "benignOverfitLecture": benignOverfitLecture,
};
